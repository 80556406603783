import React, { useEffect, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  ModalCloseButton,
} from '@chakra-ui/react';

import { detectBrowser } from '@/utils/client';
import useAnalyticsEventTracker, { AlphaActions } from '@/utils/ga';

import s from './NotEnoughStorage.module.scss';

const BROWSERS = new Map(
  ['Chrome', 'Firefox', 'Safari', 'Opera', 'Edge', 'Unknown'].map(
    (item, index) => [index, item]
  )
);
const NotEnoughStorage: React.FC = (): React.ReactElement => {
  const browser = detectBrowser();
  const gaEventTracker = useAnalyticsEventTracker();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onClose = (): void => {
    setIsOpen(false);
  };

  useEffect(() => {
    // Check if browser supports localStorage
    // https://github.com/andris9/jStorage/blob/master/jstorage.js#L176
    if ('localStorage' in window) {
      try {
        window.localStorage.setItem('_tmp_test', 'tmp_val');
        window.localStorage.removeItem('_tmp_test');
      } catch (BogusQuotaExceededErrorOnIos5) {
        // Thanks be to iOS5 Private Browsing mode which throws
        // QUOTA_EXCEEDED_ERRROR DOM Exception 22.
        setIsOpen(true);
        gaEventTracker(AlphaActions.OpenNotEnoughStorage);
      }
    }
  }, []);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent p="40px 20px 32px 20px" minW={'90%'}>
        <ModalBody>
          <div className={s.notEnoughStorage}>
            <ModalCloseButton className={s.btnClose} />
            <h6 className={s.title}>Not enough storage</h6>
            <div className={s.text}>
              {BROWSERS.get(browser)} does not have enough storage to proceed.
            </div>
            <div className={s.tips}>
              <div>Tips:</div>
              <ul>
                <li>
                  Go to Settings &rarr; {BROWSERS.get(browser)} &rarr; Clear
                  History and Website Data.
                </li>
                <li>Clear your {BROWSERS.get(browser)} browser's cache.</li>
              </ul>
            </div>
            <div className={s.note}>
              Note: please double-check if you've saved any important
              information in your local storage, as we won't be responsible for
              any data loss after clearing the cache.
            </div>
          </div>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NotEnoughStorage;
