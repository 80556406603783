import QRCodeGenerator from '@/components/QRCodeGenerator';
import { CDN_URL_ICONS } from '@/configs';
import { ROUTE_PATH } from '@/constants/route-path';
import { TOKEN_TAB_INDEX, TWITTER_TOKEN } from '@/constants/storage-key';
import { AssetsContext } from '@/contexts/assets-context';
import { WalletContext } from '@/contexts/wallet-context';
import withPWAMiddleware from '@/hocs/withAlphaMiddleware';
import useWindowSize from '@/hooks/useWindowSize';
import { setBearerToken as setBearerTokenTweet } from '@/services/alpha_tweet';
import { generateToken, setBearerToken } from '@/services/player-share';
import { isWebApp } from '@/utils/client';
import { isProduction } from '@/utils/commons';
import { getUuid } from '@/utils/helpers';
import localStorage from '@/utils/localstorage';
import cn from 'classnames';
import copy from 'copy-to-clipboard';
import { useRouter } from 'next/router';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { toast } from 'react-hot-toast';
import s from './Welcome.module.scss';
import TwitterSignIn from './TwitterSignIn';
import NotEnoughStorage from './NotEnoughStorage';
import { TokensTabIndex } from '@/modules/AlphaPWA/Tokens';
import { TabIndex } from '../Keys';

const WelcomeScreen: React.FC = () => {
  const router = useRouter();
  const { addressL2 } = useContext(WalletContext);
  const { tabletScreen } = useWindowSize();
  const { playerPoolProfile } = useContext(AssetsContext);
  const isPWA = isWebApp();
  // const timer = useRef<any>();
  // const uuid = getUuid();
  // const [hasLinkTwitter, setHasLinkTwitter] = useState<boolean>(false);
  // const [isClickLinkTwitter, setIsClickLinkTwitter] = useState<boolean>(false);

  // const { token = '' } = router.query;

  const DOWNLOAD_URL = `https://${isProduction() ? '' : 'dev.'}alpha.wtf/app`;
  const isAppCheckingReady =
    (addressL2 && playerPoolProfile === null && isPWA) ||
    (typeof tabletScreen === 'undefined' && isPWA);

  const onClickCopy = (text: string) => {
    copy(text);
    toast.remove();
    toast.success('Copied');
  };

  // const handleVerifyTwitter = async (): Promise<void> => {
  //   const result = await generateToken(uuid);
  //   if (result) {
  //     clearInterval(timer.current);
  //     const twitterToken = localStorage.get(TWITTER_TOKEN);
  //     if (!twitterToken || twitterToken !== result?.token) {
  //       localStorage.set(TWITTER_TOKEN, result?.token);
  //       setBearerToken(result?.token);
  //       setBearerTokenTweet(result?.token);
  //     }
  //     setHasLinkTwitter(true);
  //   }
  // };

  useEffect(() => {
    if (addressL2 && playerPoolProfile?.twitterId && isPWA) {
      localStorage.set(TOKEN_TAB_INDEX, TabIndex.Tokens.toString());
      router.push(ROUTE_PATH.ALPHA_MOBILE_KEYS);
    }
  }, [addressL2, playerPoolProfile]);

  // useEffect(() => {
  //   if (
  //     (isClickLinkTwitter && !addressL2) ||
  //     (isClickLinkTwitter && !playerPoolProfile?.twitterId)
  //   ) {
  //     timer.current = setInterval(async () => {
  //       handleVerifyTwitter();
  //     }, 2000);
  //   }
  //   return () => {
  //     clearInterval(timer.current);
  //   };
  // }, [isClickLinkTwitter, addressL2, playerPoolProfile]);

  // useEffect(() => {
  //   if (token) {
  //     handleVerifyTwitter();
  //   }
  // }, [token]);

  return (
    <>
      <NotEnoughStorage />
      {isAppCheckingReady ? (
        <div className={s.loadingWrapper}>
          <Spinner variant="info" />
        </div>
      ) : null}
      <div className={cn(isAppCheckingReady && 'd-none')}>
        {tabletScreen ? (
          <TwitterSignIn
            // hasLinkTwitters={hasLinkTwitter}
            // setIsClickLinkTwitter={setIsClickLinkTwitter}
          />
        ) : (
          <div className={s.jackpotDeposit_web}>
            <div className={s.welcome_pc}>
              <div className={s.welcome_web_left}>
                <div>
                  <div className={s.logo_wrapper}>
                    <img
                      className={s.logo}
                      src={`${CDN_URL_ICONS}/icon-download-app.svg`}
                      alt="logo"
                    />
                  </div>
                  <div className={s.listPositive}>
                    <ul>
                      <li>
                        <img
                          alt="check-single"
                          src={`${CDN_URL_ICONS}/check-single.svg`}
                        />
                        <span>One-second installation</span>
                      </li>
                      <li>
                        <img
                          alt="check-single"
                          src={`${CDN_URL_ICONS}/check-single.svg`}
                        />
                        No wallet or deposit required
                      </li>
                      <li>
                        <img
                          alt="check-single"
                          src={`${CDN_URL_ICONS}/check-single.svg`}
                        />
                        Chat, play games, and trade
                      </li>
                    </ul>
                  </div>
                  <p className={s.welcome_web_left_heading}>
                    Open the link in a phone browser to install the Alpha app.
                  </p>
                  <div
                    className={s.link}
                    onClick={() => onClickCopy(DOWNLOAD_URL)}
                  >
                    <span>{DOWNLOAD_URL}</span>
                  </div>
                  <div className={s.wqr}>
                    <p>Or simply scan the QR code.</p>
                    <div className={s.qr}>
                      <QRCodeGenerator value={DOWNLOAD_URL} size={132} />
                    </div>
                    <p className={s.installText}>
                      <span>Available on both </span>&nbsp;
                      <img src={`${CDN_URL_ICONS}/ic-ios.svg`} alt="" />
                      &nbsp;<b>iOS</b>&nbsp;<span>and</span>&nbsp;
                      <img src={`${CDN_URL_ICONS}/android.svg`} alt="" />
                      &nbsp;<b>Android</b>
                    </p>
                    <div className={s.notes}>
                      Alpha uses progressive web app (PWA) technology that
                      combines the best features of traditional websites and
                      platform-specific apps.
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default withPWAMiddleware(WelcomeScreen);
